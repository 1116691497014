import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../../actions/user";
import {resetHeader} from "../../../actions/header";
import {closeDialog, openModalDialog} from "../../../actions/modalDialog";
import {connect} from "react-redux";
import OptionSwitcher from "../../../components/GUI/OptionSwitcher";
import WhitelistedDomains from "../WhitelistedDomains";
import {exposeErrors} from "../../../utils/errors";
import {changeSetting} from "../../../services/accounts.service";
import lang from "../../../utils/lang";

class SecurityCompanySettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updatingPrivateExtraData: false
        }
    }

    render() {
        return <>
            <div className="setting-page-title">{lang.d('security')}</div>
            {this.renderPrivateExtraData()}
            {this.renderWhitelistedDomains()}
            {this.renderAllowRenderingOnLocalhost()}
        </>
    }

    renderPrivateExtraData() {
        return <div className="setting">
            <div className="title">Extra Data</div>
            <div className="text">
                <p>When set to Public, <a href="https://docs.seats.io/docs/api/extra-data/" target="_blank"
                    rel="noopener noreferrer">extraData</a> will be made available to both
                    Renderer and Event Manager. When set to Private, extraData will only be made available to Event
                    Manager.</p>
                <p>If you store data like attendee names, phone numbers or email addresses in <code>extraData</code>,
                    this should be set to Private.</p>
            </div>
            <OptionSwitcher
                value={this.props.setting.privateExtraData.toString().toUpperCase()}
                options={{
                    TRUE: 'Private',
                    FALSE: 'Public'
                }}
                loading={this.state.updatingPrivateExtraData}
                onChange={value => this.setPrivateExtraData(value)}
            />
        </div>
    }

    renderWhitelistedDomains() {
        return <div className="setting">
            <div className="notice">
                <div className="title">Hint</div>
                <div className="description">
                    <p>
                        Only specify the domain name, e.g. <b>mydomain.com</b>.<br/>
                        So not https://www.mydomain.com or www.mydomain.com.
                    </p>
                </div>
            </div>
            <div className="title">Whitelisted Domains</div>
            <div className="text">
                <p>This setting allows you to specify a list of domains on which your seating charts
                    are allowed to be rendered.</p>
                <p>If the whitelist is empty, your seating charts can be rendered anywhere.</p>
                <p>If the whitelist is not empty, then your seating charts will only render on the specified domains.
                    An error message will be displayed when rendering on other domains.</p>
            </div>

            <WhitelistedDomains whitelistedDomains={this.props.setting.whitelistedDomains}/>
        </div>
    }

    renderAllowRenderingOnLocalhost() {
        return <div className="setting">
            <div className="title">Allow rendering on localhost</div>
            <div className="text">
                <p>
                    This setting allows you to specify whether your seating charts are allowed to be rendered on a local machine or not.
                </p>
                <p>
                    When set to <b>Allow</b>, your seating charts can be rendered on both <code>localhost</code> and <code>127.0.0.1</code>,
                    which is useful for development purposes.
                </p>
            </div>

            <OptionSwitcher
                value={this.props.setting.allowRenderingOnLocalhost.toString().toUpperCase()}
                options={{
                    TRUE: 'Allow',
                    FALSE: 'Disallow'
                }}
                loading={this.state.updatingPrivateExtraData}
                onChange={value => this.setAllowRenderingOnLocalhost(value)}
            />
        </div>
    }

    async setAllowRenderingOnLocalhost(value) {
        const oldValue = this.props.setting.allowRenderingOnLocalhost
        this.props.setSetting('allowRenderingOnLocalhost', value)
        this.setState({
            updatingAllowRenderingOnLocalhost: true
        })
        try {
            await exposeErrors(changeSetting('ALLOW_RENDERING_ON_LOCALHOST', value), () => this.props.setSetting('allowRenderingOnLocalhost', oldValue))
            this.props.setSetting('allowRenderingOnLocalhost', value)
        } finally {
            this.setState({
                updatingAllowRenderingOnLocalhost: false
            })
        }
    }

    async setPrivateExtraData(value) {
        const oldValue = this.props.setting.privateExtraData
        this.props.setSetting('privateExtraData', value)
        this.setState({
            updatingPrivateExtraData: true
        })
        try {
            await exposeErrors(changeSetting('IS_EXTRA_DATA_PRIVATE', value), () => this.props.setSetting('privateExtraData', oldValue))
            this.props.setSetting('privateExtraData', value)
        } finally {
            this.setState({
                updatingPrivateExtraData: false
            })
        }
    }


}


SecurityCompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityCompanySettings)
